.styled-tooltip.top {
  transform: translate(-50%, -100%);
  left: 50%;
  top: -4px;
}

.styled-tooltip.top::after {
  border-top: 6px solid #333;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  content: '';
  position: absolute;
  left: 50%;
  bottom: -6px;
  transform: translate(-50%, 0);
  height: 0;
  width: 0;
}

.styled-tooltip.bottom {
  transform: translate(-50%, 100%);
  left: 50%;
  bottom: -4px;
}

.styled-tooltip.bottom::after {
  border-bottom: 6px solid #333;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  content: '';
  position: absolute;
  left: 50%;
  top: -6px;
  transform: translate(-50%, 0);
  height: 0;
  width: 0;
}

.styled-tooltip.left {
  transform: translate(-100%, -50%);
  top: 50%;
  left: -8px;
}

.styled-tooltip.left::after {
  border-left: 6px solid #333;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  right: -6px;
  transform: translate(0, -50%);
  height: 0;
  width: 0;
}

.styled-tooltip.right {
  transform: translate(100%, -50%);
  top: 50%;
  right: -8px;
}

.styled-tooltip.right::after {
  border-right: 6px solid #333;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  left: -6px;
  transform: translate(0, -50%);
  height: 0;
  width: 0;
}

.styled-tooltip-question-mark:hover .styled-tooltip {
  display: block;
}

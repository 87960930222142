@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  * {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-medium mb-2;
  }

  h1 {
    @apply text-2xl;
  }

  h2 {
    @apply text-xl;
  }

  h3 {
    @apply text-lg;
  }

  h4,
  h5,
  h6 {
    @apply text-base;
  }

  body {
    @apply bg-gray-50 text-gray-600;
    @apply font-nunito
  }

  p {
    @apply mb-2 whitespace-pre-line;
  }

  hr {
    @apply my-4 border-t border-solid;
    border-color: rgba(0, 0, 0, 0.1);
  }

  .deposit-option > div {
    @apply flex flex-col items-center justify-between
      bg-white
      border-2 border-solid border-gray-300 rounded
      py-6 px-2 mb-4 w-full
      transition-colors
      hover:border-primary hover:[background:linear-gradient(165deg,_#dedede_50%,_#d2d2d2_50%)];
  }

  .deposit-option input[type='radio']:checked + div {
    @apply text-white border-primary;
    background: linear-gradient(165deg, #007ec2 50%, #005b9d 50%);
  }

  .styled-form-element label {
    @apply inline-block pb-1 w-full;
  }

  .styled-form-element select{
    @apply appearance-none bg-no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
    background-position: 100%;
  }
  .styled-form-element input,
  .styled-form-element select,
  .styled-form-element textarea,
  .styled-form-element .StripeElement {
    @apply block w-full py-1.5 px-3
      bg-clip-padding transition-colors bg-white
      text-base font-normal text-gray-600
      border border-solid rounded border-gray-300
      focus:border-blue-300 focus:outline-none h-9
      disabled:bg-gray-200;
  }

  .styled-form-element textarea {
    height: auto;
  }

  .styled-form-element input:focus,
  .styled-form-element select:focus,
  .styled-form-element textarea:focus,
  .styled-form-element .StripeElement:focus {
    box-shadow: 0 0 0 0.2rem #02539f40;
  }

  .styled-deposit-option input[type='radio']:checked + div {
    @apply bg-primary;
    border-color: #BFBFBF;
  }

  .styled-deposit-option input[type='radio']:checked + div > * {
    @apply text-white;
  }

  .radio-option .radio-option-checkbox {
    @apply border-2 border-solid border-gray-300 rounded-full flex flex-grow-0 flex-shrink-0 w-6 h-6 relative mr-2 before:bg-gray-100 before:rounded-full before:absolute before:w-4 before:h-4 before:transition-colors before:content-[''] before:[top:2px] before:[left:2px];
  }

  .radio-option .radio-option-body {
    @apply flex cursor-pointer flex-1 bg-white border-2 border-solid border-gray-300 rounded flex-nowrap items-start p-2 mb-2 transition-colors;
  }

  .radio-option.radio-vertical .radio-option-body {
    @apply flex cursor-pointer flex-1 bg-white border-solid border-gray-300 rounded flex-nowrap items-start p-2 mb-2 transition-colors scale-100;
  }

  .radio-option.radio-vertical .radio-option-body:hover {
    @apply bg-gray-100 shadow-2xl
  }
  .radio-option .radio-option-body:hover {
    @apply border-gray-500;
  }
  .radio-option.radio-vertical .radio-option-body:hover {
    @apply border-gray-400 scale-[101%] duration-500;
  }

  .radio-option .radio-option-body:hover .radio-option-checkbox:before {
    @apply bg-gray-500;
  }

  .radio-option .radio-option-input {
    @apply w-0 h-0 invisible hidden;
  }

  .radio-option .radio-option-input:focus + .radio-option-body {
    @apply border-gray-500;
  }

  .radio-option .radio-option-input:focus + .radio-option-body .radio-option-checkbox:before {
    @apply bg-gray-500;
  }

  .radio-option .radio-option-input:disabled + .radio-option-body {
    @apply cursor-not-allowed bg-gray-200 border-gray-200 pointer-events-none;
  }

  .radio-option .radio-option-input:disabled + .radio-option-body:hover {
    @apply border-gray-200;
  }

  .radio-option .radio-option-input:disabled + .radio-option-body:hover .radio-option-checkbox:before {
    @apply border-gray-400 bg-gray-400;
  }

  .radio-option .radio-option-input:checked + .radio-option-body {
    @apply bg-blue-50 border-3;

  }
  .radio-option.radio-vertical .radio-option-input:checked .radio-header{
    @apply text-primary
  }
  .radio-option.radio-vertical .radio-option-input:checked + .radio-option-body {
    @apply border-blue-600/30 shadow-lg  shadow-blue-300/30 z-10;
  }
  .radio-option.radio-vertical .radio-option-input + .radio-option-body .radio-description{
    @apply text-gray-500 border-t;
  }

  .radio-option.radio-vertical .radio-option-input:checked + .radio-option-body .radio-description{
    @apply bg-blue-100 !text-blue-700
  }

  .radio-option.radio-vertical .radio-option-input + .radio-option-body .radio-badge{
    @apply bg-blue-100 !text-blue-700
  }

  .radio-option.radio-vertical .radio-option-input:checked + .radio-option-body .radio-badge{
    @apply bg-blue-700 !text-blue-100
  }


  .radio-option .radio-option-input:checked + .radio-option-body:hover {
    @apply border-primary;
  }

  .radio-option.radio-option.radio-vertical .radio-option-input:checked + .radio-option-body:hover {
    @apply border-blue-700/40 shadow-lg shadow-cyan-700/30;
  }

  .radio-option .radio-option-input:checked + .radio-option-body:hover .radio-option-checkbox:before {
    @apply bg-primary;
  }

  .radio-option .radio-option-input:checked + .radio-option-body .radio-option-checkbox:before {
    @apply bg-primary;
  }

  .options-list:hover .options-list__checkbox:before {
    @apply bg-gray-500;
  }

  .options-list .options-list__checkbox {
    @apply border-2 border-solid border-gray-300 rounded-full w-6 h-6 flex-grow-0 flex-shrink-0 relative mr-2;
  }

  .options-list .options-list__checkbox:before {
    @apply bg-gray-100 rounded-full absolute w-4 h-4 transition-colors;
    content: '';
    top: 2px;
    left: 2px;
  }

  .options-list__is-selected:hover .options-list__checkbox:before {
    @apply bg-primary;
  }

  .options-list__is-selected .options-list__checkbox:before {
    @apply bg-primary;
  }

  .options-list__is-disabled:hover .options-list__checkbox:before {
    @apply border-gray-400 bg-gray-400;
  }

  .options-list__is-disabled .options-list__checkbox:before {
    @apply border-gray-400 bg-gray-400;
  }

  .payment-option img {
    @apply h-6;
  }
}

.c_ribbon {
  color: #ffffff;
  background-color: #1a539f;
  width: clamp(10em, 50%, 16em);
  text-align: center;
  text-transform: uppercase;
  border: 2px solid transparent;
  border-radius: 2px 2px 12px 12px;
  font-size: 12px;
  margin: auto;
  padding: 5px;
  position: relative;
  top: -8px;
  left: 6px;
  box-shadow: -3px 4px 3px -2px #00000075;
}

.c_ribbon span {
  position: absolute;
}

.c_ribbon .flair-top-left {
  filter: brightness(0.5);
  z-index: -1;
  border: 3px solid #3a3f72;
  border-color: transparent #3a3f72 #3a3f72 transparent;
  top: -2px;
  left: -7px;
}

.c_ribbon .flair-top-right {
  z-index: -1;
  border: 3px solid #3a3f72;
  border-color: transparent transparent #3a3f72 #3a3f72;
  top: -2px;
  right: -7px;
}

.c_ribbon .flair-bottom-right {
  z-index: -1;
  border: 3px solid #3a3f72;
  border-color: #3a3f72 transparent transparent #3a3f72;
  right: -2px;
  bottom: -7px;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, AppleSystem, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(3, 118, 227, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(3, 118, 227, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-weight: 500;
}

h1 {
  font-size: 1.5rem;
  line-height: 2rem;
}

h2 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

h3 {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

h4, h5, h6 {
  font-size: 1rem;
  line-height: 1.5rem;
}

body {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity) );
  font-family: Nunito, sans-serif;
}

p {
  white-space: pre-line;
  margin-bottom: .5rem;
}

hr {
  border-style: solid;
  border-color: rgba(0, 0, 0, .1);
  border-top-width: 1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.deposit-option > div {
  width: 100%;
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  border-radius: .25rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1.5rem .5rem;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.deposit-option > div:hover {
  --tw-border-opacity: 1;
  border-color: rgb(2 83 159 / var(--tw-border-opacity) );
  background: linear-gradient(165deg, #dedede 50%, #d2d2d2 50%);
}

.deposit-option input[type="radio"]:checked + div {
  --tw-border-opacity: 1;
  border-color: rgb(2 83 159 / var(--tw-border-opacity) );
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
  background: linear-gradient(165deg, #007ec2 50%, #005b9d 50%);
}

.styled-form-element label {
  width: 100%;
  padding-bottom: .25rem;
  display: inline-block;
}

.styled-form-element select {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC");
  background-position: 100%;
  background-repeat: no-repeat;
}

.styled-form-element input, .styled-form-element select, .styled-form-element textarea, .styled-form-element .StripeElement {
  height: 2.25rem;
  width: 100%;
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity) );
  background-clip: padding-box;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
}

.styled-form-element input:focus, .styled-form-element select:focus, .styled-form-element textarea:focus, .styled-form-element .StripeElement:focus {
  --tw-border-opacity: 1;
  border-color: rgb(79 169 253 / var(--tw-border-opacity) );
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.styled-form-element input:disabled, .styled-form-element select:disabled, .styled-form-element textarea:disabled, .styled-form-element .StripeElement:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity) );
}

.styled-form-element textarea {
  height: auto;
}

.styled-form-element input:focus, .styled-form-element select:focus, .styled-form-element textarea:focus, .styled-form-element .StripeElement:focus {
  box-shadow: 0 0 0 .2rem rgba(2, 83, 159, .25);
}

.styled-deposit-option input[type="radio"]:checked + div {
  --tw-bg-opacity: 1;
  background-color: rgb(2 83 159 / var(--tw-bg-opacity) );
  border-color: #bfbfbf;
}

.styled-deposit-option input[type="radio"]:checked + div > * {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.radio-option .radio-option-checkbox {
  height: 1.5rem;
  width: 1.5rem;
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
  border-radius: 9999px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: .5rem;
  display: flex;
  position: relative;
}

.radio-option .radio-option-checkbox:before {
  height: 1rem;
  width: 1rem;
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity) );
  --tw-content: "";
  content: var(--tw-content);
  border-radius: 9999px;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 2px;
  left: 2px;
}

.radio-option .radio-option-body {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  border-radius: .25rem;
  flex-wrap: nowrap;
  flex: 1;
  align-items: flex-start;
  margin-bottom: .5rem;
  padding: .5rem;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.radio-option.radio-vertical .radio-option-body {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  cursor: pointer;
  --tw-border-opacity: 1;
  border-style: solid;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  border-radius: .25rem;
  flex-wrap: nowrap;
  flex: 1;
  align-items: flex-start;
  margin-bottom: .5rem;
  padding: .5rem;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.radio-option.radio-vertical .radio-option-body:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity) );
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, .25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.radio-option .radio-option-body:hover {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity) );
}

.radio-option.radio-vertical .radio-option-body:hover {
  --tw-scale-x: 101%;
  --tw-scale-y: 101%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity) );
  transition-duration: .5s;
}

.radio-option .radio-option-body:hover .radio-option-checkbox:before {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity) );
}

.radio-option .radio-option-input {
  visibility: hidden;
  height: 0;
  width: 0;
  display: none;
}

.radio-option .radio-option-input:focus + .radio-option-body {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity) );
}

.radio-option .radio-option-input:focus + .radio-option-body .radio-option-checkbox:before {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity) );
}

.radio-option .radio-option-input:disabled + .radio-option-body {
  pointer-events: none;
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity) );
}

.radio-option .radio-option-input:disabled + .radio-option-body:hover {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity) );
}

.radio-option .radio-option-input:disabled + .radio-option-body:hover .radio-option-checkbox:before {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity) );
}

.radio-option .radio-option-input:checked + .radio-option-body {
  --tw-bg-opacity: 1;
  background-color: rgb(230 243 255 / var(--tw-bg-opacity) );
  border-width: 3px;
}

.radio-option.radio-vertical .radio-option-input:checked .radio-header {
  --tw-text-opacity: 1;
  color: rgb(2 83 159 / var(--tw-text-opacity) );
}

.radio-option.radio-vertical .radio-option-input:checked + .radio-option-body {
  z-index: 10;
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  --tw-shadow-color: rgba(79, 169, 253, .3);
  --tw-shadow: var(--tw-shadow-colored);
  border-color: rgba(2, 92, 176, .3);
}

.radio-option.radio-vertical .radio-option-input + .radio-option-body .radio-description {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
  border-top-width: 1px;
}

.radio-option.radio-vertical .radio-option-input:checked + .radio-option-body .radio-description, .radio-option.radio-vertical .radio-option-input + .radio-option-body .radio-badge {
  --tw-bg-opacity: 1;
  background-color: rgb(179 218 254 / var(--tw-bg-opacity) );
  --tw-text-opacity: 1 !important;
  color: rgb(2 66 126 / var(--tw-text-opacity) ) !important;
}

.radio-option.radio-vertical .radio-option-input:checked + .radio-option-body .radio-badge {
  --tw-bg-opacity: 1;
  background-color: rgb(2 66 126 / var(--tw-bg-opacity) );
  --tw-text-opacity: 1 !important;
  color: rgb(179 218 254 / var(--tw-text-opacity) ) !important;
}

.radio-option .radio-option-input:checked + .radio-option-body:hover {
  --tw-border-opacity: 1;
  border-color: rgb(2 83 159 / var(--tw-border-opacity) );
}

.radio-option.radio-option.radio-vertical .radio-option-input:checked + .radio-option-body:hover {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  --tw-shadow-color: rgba(14, 116, 144, .3);
  --tw-shadow: var(--tw-shadow-colored);
  border-color: rgba(2, 66, 126, .4);
}

.radio-option .radio-option-input:checked + .radio-option-body:hover .radio-option-checkbox:before, .radio-option .radio-option-input:checked + .radio-option-body .radio-option-checkbox:before {
  --tw-bg-opacity: 1;
  background-color: rgb(2 83 159 / var(--tw-bg-opacity) );
}

.options-list:hover .options-list__checkbox:before {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity) );
}

.options-list .options-list__checkbox {
  height: 1.5rem;
  width: 1.5rem;
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
  border-radius: 9999px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: .5rem;
  position: relative;
}

.options-list .options-list__checkbox:before {
  height: 1rem;
  width: 1rem;
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity) );
  content: "";
  border-radius: 9999px;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 2px;
  left: 2px;
}

.options-list__is-selected:hover .options-list__checkbox:before, .options-list__is-selected .options-list__checkbox:before {
  --tw-bg-opacity: 1;
  background-color: rgb(2 83 159 / var(--tw-bg-opacity) );
}

.options-list__is-disabled:hover .options-list__checkbox:before, .options-list__is-disabled .options-list__checkbox:before {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity) );
}

.payment-option img {
  height: 1.5rem;
}

.pointer-events-none {
  pointer-events: none;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.top-\[-1\.25rem\] {
  top: -1.25rem;
}

.left-\[50\%\] {
  left: 50%;
}

.top-4 {
  top: 1rem;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.top-2 {
  top: .5rem;
}

.right-2 {
  right: .5rem;
}

.left-auto {
  left: auto;
}

.right-0 {
  right: 0;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.m-auto {
  margin: auto;
}

.m-0 {
  margin: 0;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-0\.5 {
  margin-left: .125rem;
  margin-right: .125rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.\!mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.\!my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.\!mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.ml-3 {
  margin-left: .75rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.\!mb-0 {
  margin-bottom: 0 !important;
}

.\!mb-3 {
  margin-bottom: .75rem !important;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-2 {
  margin-right: .5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-32 {
  margin-top: 8rem;
}

.-mb-1 {
  margin-bottom: -.25rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mt-1 {
  margin-top: .25rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.-mr-2 {
  margin-right: -.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.\!mt-4 {
  margin-top: 1rem !important;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.\!mb-2 {
  margin-bottom: .5rem !important;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-0 {
  margin-top: 0;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.\!mr-2 {
  margin-right: .5rem !important;
}

.-mr-1 {
  margin-right: -.25rem;
}

.\!mr-1 {
  margin-right: .25rem !important;
}

.-mr-px {
  margin-right: -1px;
}

.-ml-px {
  margin-left: -1px;
}

.mr-3 {
  margin-right: .75rem;
}

.block {
  display: block;
}

.\!block {
  display: block !important;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.\!flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.\!hidden {
  display: none !important;
}

.h-4 {
  height: 1rem;
}

.h-8 {
  height: 2rem;
}

.h-3 {
  height: .75rem;
}

.h-6 {
  height: 1.5rem;
}

.\!h-6 {
  height: 1.5rem !important;
}

.h-full {
  height: 100%;
}

.h-5 {
  height: 1.25rem;
}

.h-9 {
  height: 2.25rem;
}

.h-auto {
  height: auto;
}

.\!h-12 {
  height: 3rem !important;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.\!h-full {
  height: 100% !important;
}

.h-0 {
  height: 0;
}

.h-16 {
  height: 4rem;
}

.h-7 {
  height: 1.75rem;
}

.\!h-7 {
  height: 1.75rem !important;
}

.min-h-\[15em\] {
  min-height: 15em;
}

.w-full {
  width: 100%;
}

.w-4 {
  width: 1rem;
}

.w-32 {
  width: 8rem;
}

.w-10\/12 {
  width: 83.3333%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-3 {
  width: .75rem;
}

.w-48 {
  width: 12rem;
}

.w-6 {
  width: 1.5rem;
}

.\!w-6 {
  width: 1.5rem !important;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-1\/2 {
  width: 50%;
}

.w-5 {
  width: 1.25rem;
}

.w-8 {
  width: 2rem;
}

.w-0 {
  width: 0;
}

.w-72 {
  width: 18rem;
}

.w-7 {
  width: 1.75rem;
}

.\!w-7 {
  width: 1.75rem !important;
}

.w-3\/4 {
  width: 75%;
}

.w-1\/4 {
  width: 25%;
}

.w-\[50em\] {
  width: 50em;
}

.min-w-\[128px\] {
  min-width: 128px;
}

.max-w-\[270px\] {
  max-width: 270px;
}

.max-w-xl {
  max-width: 36rem;
}

.\!flex-col-12 {
  flex: 0 0 100% !important;
}

.flex-1 {
  flex: 1;
}

.\!flex-col-6 {
  flex: 0 0 50% !important;
}

.flex-col-6 {
  flex: 0 0 50%;
}

.\!flex-col-1 {
  flex: 0 0 8.33333% !important;
}

.\!flex-col-3 {
  flex: 0 0 25% !important;
}

.\!flex-col-2 {
  flex: 0 0 16.6667% !important;
}

.\!flex-col-8 {
  flex: 0 0 66.6667% !important;
}

.flex-col-8 {
  flex: 0 0 66.6667%;
}

.\!flex-col-4 {
  flex: 0 0 33.3333% !important;
}

.\!flex-col-9 {
  flex: 0 0 75% !important;
}

.\!flex-1 {
  flex: 1 !important;
}

.flex-col-12 {
  flex: 0 0 100%;
}

.flex-auto {
  flex: auto;
}

.\!flex-none {
  flex: none !important;
}

.\!flex-col-7 {
  flex: 0 0 58.3333% !important;
}

.flex-shrink {
  flex-shrink: 1;
}

.\!flex-grow {
  flex-grow: 1 !important;
}

.flex-grow {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.\!flex-grow-0 {
  flex-grow: 0 !important;
}

.grow-\[2\] {
  flex-grow: 2;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.origin-center {
  transform-origin: center;
}

.-translate-x-2\/4 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.select-none {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.list-decimal {
  list-style-type: decimal;
}

.list-none {
  list-style-type: none;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.\!flex-nowrap {
  flex-wrap: nowrap !important;
}

.content-center {
  align-content: center;
}

.content-start {
  align-content: flex-start;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.\!items-end {
  align-items: flex-end !important;
}

.items-center {
  align-items: center;
}

.\!items-center {
  align-items: center !important;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.\!justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.\!justify-between {
  justify-content: space-between !important;
}

.justify-around {
  justify-content: space-around;
}

.gap-4 {
  gap: 1rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-1 {
  gap: .25rem;
}

.\!gap-1 {
  gap: .25rem !important;
}

.gap-8 {
  gap: 2rem;
}

.gap-y-3 {
  row-gap: .75rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse) );
}

.self-center {
  align-self: center;
}

.overflow-auto {
  overflow: auto;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-sm {
  border-radius: .125rem;
}

.\!rounded {
  border-radius: .25rem !important;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-2xl {
  border-radius: 1rem;
}

.\!rounded-lg {
  border-radius: .5rem !important;
}

.rounded-l-lg {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-r-lg {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.rounded-b {
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.\!rounded-r-none {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.\!rounded-l-none {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.\!rounded-l {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.\!rounded-r {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-4 {
  border-width: 4px;
}

.border-2 {
  border-width: 2px;
}

.border-0 {
  border-width: 0;
}

.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l-3 {
  border-left-width: 3px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-b-3 {
  border-bottom-width: 3px;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-solid {
  border-style: solid;
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
}

.border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgb(110 231 183 / var(--tw-border-opacity) );
}

.border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgb(252 211 77 / var(--tw-border-opacity) );
}

.border-current {
  border-color: currentColor;
}

.border-yellow-200 {
  --tw-border-opacity: 1;
  border-color: rgb(253 230 138 / var(--tw-border-opacity) );
}

.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity) );
}

.border-transparent {
  border-color: rgba(0, 0, 0, 0);
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity) );
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity) );
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(2 83 159 / var(--tw-border-opacity) );
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity) );
}

.border-error {
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity) );
}

.border-slate-400 {
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity) );
}

.border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgb(2 92 176 / var(--tw-border-opacity) );
}

.border-success {
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity) );
}

.border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgb(245 158 11 / var(--tw-border-opacity) );
}

.border-slate-300 {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity) );
}

.border-l-amber-300 {
  --tw-border-opacity: 1;
  border-left-color: rgb(252 211 77 / var(--tw-border-opacity) );
}

.border-r-transparent {
  border-right-color: rgba(0, 0, 0, 0);
}

.border-b-primary {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(2 83 159 / var(--tw-border-opacity) );
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(110 231 183 / var(--tw-bg-opacity) );
}

.bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 211 77 / var(--tw-bg-opacity) );
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(2 83 159 / var(--tw-bg-opacity) );
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 243 199 / var(--tw-bg-opacity) );
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity) );
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity) );
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 66 126 / var(--tw-bg-opacity) );
}

.\!bg-gray-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity) ) !important;
}

.bg-slate-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity) );
}

.bg-stone-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 244 / var(--tw-bg-opacity) );
}

.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(4 120 87 / var(--tw-bg-opacity) );
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity) );
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(52 211 153 / var(--tw-bg-opacity) );
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity) );
}

.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 230 138 / var(--tw-bg-opacity) );
}

.bg-success {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity) );
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 250 229 / var(--tw-bg-opacity) );
}

.bg-\[\#FEF9E9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 233 / var(--tw-bg-opacity) );
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity) );
}

.bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(28 144 252 / var(--tw-bg-opacity) );
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(179 218 254 / var(--tw-bg-opacity) );
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity) );
}

.bg-transparent {
  background-color: rgba(0, 0, 0, 0);
}

.bg-error {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity) );
}

.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity) );
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-none {
  background-image: none;
}

.bg-clip-border {
  background-clip: border-box;
}

.fill-slate-400 {
  fill: #94a3b8;
}

.fill-green-800 {
  fill: #065f46;
}

.fill-\[\#F9C12D\] {
  fill: #f9c12d;
}

.fill-blue-500 {
  fill: #0376e3;
}

.fill-orange-600 {
  fill: #ea580c;
}

.fill-gray-600 {
  fill: #4b5563;
}

.fill-slate-600 {
  fill: #475569;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-8 {
  padding: 2rem;
}

.p-0 {
  padding: 0;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-7 {
  padding: 1.75rem;
}

.\!p-4 {
  padding: 1rem !important;
}

.p-1\.5 {
  padding: .375rem;
}

.p-1 {
  padding: .25rem;
}

.\!p-0 {
  padding: 0 !important;
}

.p-5 {
  padding: 1.25rem;
}

.\!px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.\!py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.\!px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.\!px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.\!px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.pt-4 {
  padding-top: 1rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-0 {
  padding-left: 0;
}

.\!pl-7 {
  padding-left: 1.75rem !important;
}

.pt-5 {
  padding-top: 1.25rem;
}

.\!pt-6 {
  padding-top: 1.5rem !important;
}

.\!pr-4 {
  padding-right: 1rem !important;
}

.pl-4 {
  padding-left: 1rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.\!pr-0 {
  padding-right: 0 !important;
}

.pr-0 {
  padding-right: 0;
}

.\!pt-4 {
  padding-top: 1rem !important;
}

.\!pt-0 {
  padding-top: 0 !important;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.\!pb-4 {
  padding-bottom: 1rem !important;
}

.pt-0\.5 {
  padding-top: .125rem;
}

.pt-0 {
  padding-top: 0;
}

.\!pb-0 {
  padding-bottom: 0 !important;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pl-2 {
  padding-left: .5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.\!text-center {
  text-align: center !important;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xxs {
  font-size: .75rem;
  line-height: 1.125rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.font-medium {
  font-weight: 500;
}

.font-extrabold {
  font-weight: 800;
}

.font-light {
  font-weight: 300;
}

.font-extralight {
  font-weight: 200;
}

.font-normal {
  font-weight: 400;
}

.italic {
  font-style: italic;
}

.leading-none {
  line-height: 1;
}

.leading-loose {
  line-height: 2;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-tight {
  line-height: 1.25;
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity) );
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity) );
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity) );
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.text-error {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity) );
}

.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgb(251 191 36 / var(--tw-text-opacity) );
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity) );
}

.\!text-primary {
  --tw-text-opacity: 1 !important;
  color: rgb(2 83 159 / var(--tw-text-opacity) ) !important;
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(6 95 70 / var(--tw-text-opacity) );
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity) );
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(3 118 227 / var(--tw-text-opacity) );
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity) );
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity) );
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity) );
}

.text-blue-800 {
  --tw-text-opacity: 1;
  color: rgb(1 39 76 / var(--tw-text-opacity) );
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity) );
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(2 83 159 / var(--tw-text-opacity) );
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(2 66 126 / var(--tw-text-opacity) );
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(2 92 176 / var(--tw-text-opacity) );
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity) );
}

.text-success {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity) );
}

.text-amber-700 {
  --tw-text-opacity: 1;
  color: rgb(180 83 9 / var(--tw-text-opacity) );
}

.text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity) );
}

.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.no-underline {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

.underline-offset-4 {
  text-underline-offset: 4px;
}

.opacity-95 {
  opacity: .95;
}

.opacity-25 {
  opacity: .25;
}

.opacity-75 {
  opacity: .75;
}

.opacity-30 {
  opacity: .3;
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity) );
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-colors {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-500 {
  transition-duration: .5s;
}

.\[grid-template-columns\:1fr_min-content\] {
  grid-template-columns: 1fr min-content;
}

.\[min-width\:380px\] {
  min-width: 380px;
}

.\[max-width\:440px\] {
  max-width: 440px;
}

.\[height\:30px\] {
  height: 30px;
}

.\[width\:30px\] {
  width: 30px;
}

.\[line-height\:26px\] {
  line-height: 26px;
}

.\[background\:rgba\(155\,155\,155\,0\.5\)\] {
  background: rgba(155, 155, 155, .5);
}

.\[backdrop-filter\:blur\(2px\)\] {
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

.\[width\:55\%_\!important\] {
  width: 55% !important;
}

.\[box-shadow\:0_4px_4px_1px_\#b3b3b3\] {
  box-shadow: 0 4px 4px 1px #b3b3b3;
}

.\[min-width\:50vw\] {
  min-width: 50vw;
}

.\[min-width\:200px\] {
  min-width: 200px;
}

.\[background\:\#333\] {
  background: #333;
}

.\[top\:2px\] {
  top: 2px;
}

.\[width\:14px\] {
  width: 14px;
}

.\[transform\:rotate\(45deg\)\] {
  transform: rotate(45deg);
}

.\[top\:5px\] {
  top: 5px;
}

.\[left\:-4px\] {
  left: -4px;
}

.\[height\:14px\] {
  height: 14px;
}

.\[width\:2px\] {
  width: 2px;
}

.\[top\:0\] {
  top: 0;
}

.\[left\:11px\] {
  left: 11px;
}

.\[height\:2px\] {
  height: 2px;
}

.\[width\:5px\] {
  width: 5px;
}

.\[top\:12px\] {
  top: 12px;
}

.\[left\:6px\] {
  left: 6px;
}

.\[top\:3px\] {
  top: 3px;
}

.\[left\:-3px\] {
  left: -3px;
}

.\[width\:26px\] {
  width: 26px;
}

.\[height\:26px\] {
  height: 26px;
}

.\[height\:16px\] {
  height: 16px;
}

.\[left\:10px\] {
  left: 10px;
}

.\[width\:16px\] {
  width: 16px;
}

.\[top\:7px\] {
  top: 7px;
}

.\[left\:3px\] {
  left: 3px;
}

.\[left\:14px\] {
  left: 14px;
}

.\[height\:10px\] {
  height: 10px;
}

.\[width\:45\%\] {
  width: 45%;
}

.\[width\:25\%\] {
  width: 25%;
}

.\[width\:30\%\] {
  width: 30%;
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:border-t-2:before {
  content: var(--tw-content);
  border-top-width: 2px;
}

.before\:border-solid:before {
  content: var(--tw-content);
  border-style: solid;
}

.before\:border-dashed:before {
  content: var(--tw-content);
  border-style: dashed;
}

.before\:border-gray-300:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
}

.before\:content-\[\'\'\]:before {
  --tw-content: "";
  content: var(--tw-content);
}

.before\:\[right\:calc\(50\%_\+_15px\)\]:before {
  content: var(--tw-content);
  right: calc(50% + 15px);
}

.before\:\[top\:15px\]:before {
  content: var(--tw-content);
  top: 15px;
}

.before\:\[width\:calc\(100\%_-_30px\)\]:before {
  content: var(--tw-content);
  width: calc(100% - 30px);
}

.first\:pl-0:first-child {
  padding-left: 0;
}

.last\:mb-4:last-child {
  margin-bottom: 1rem;
}

.last\:mb-0:last-child {
  margin-bottom: 0;
}

.last\:pr-0:last-child {
  padding-right: 0;
}

.first-of-type\:before\:border-0:first-of-type:before {
  content: var(--tw-content);
  border-width: 0;
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:border-primary:hover {
  --tw-border-opacity: 1;
  border-color: rgb(2 83 159 / var(--tw-border-opacity) );
}

.hover\:border-gray-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity) );
}

.hover\:border-gray-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity) );
}

.hover\:border-gray-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity) );
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity) );
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(2 66 126 / var(--tw-bg-opacity) );
}

.hover\:bg-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity) );
}

.hover\:bg-red-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(153 27 27 / var(--tw-bg-opacity) );
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity) );
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity) );
}

.hover\:bg-blue-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(230 243 255 / var(--tw-bg-opacity) );
}

.hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(2 83 159 / var(--tw-text-opacity) );
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity) );
}

.hover\:underline:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.hover\:no-underline:hover {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

.hover\:\[background\:linear-gradient\(165deg\,\#dedede_50\%\,\#d2d2d2_50\%\)\]:hover {
  background: linear-gradient(165deg, #dedede 50%, #d2d2d2 50%);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity) );
}

.disabled\:pointer-events-none:disabled {
  pointer-events: none;
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:text-gray-300:disabled {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity) );
}

.disabled\:opacity-60:disabled {
  opacity: .6;
}

.disabled\:hover\:bg-primary:hover:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(2 83 159 / var(--tw-bg-opacity) );
}

.disabled\:hover\:bg-gray-500:hover:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity) );
}

.disabled\:hover\:bg-transparent:hover:disabled {
  background-color: rgba(0, 0, 0, 0);
}

.disabled\:hover\:text-red-500:hover:disabled {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity) );
}

.group:hover .group-hover\:flex {
  display: flex;
}

.peer:checked ~ .peer-checked\:border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgb(2 92 176 / var(--tw-border-opacity) );
}

.peer:checked ~ .peer-checked\:text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(2 92 176 / var(--tw-text-opacity) );
}

@media (prefers-color-scheme: dark) {
  .dark\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-border-opacity) );
  }

  .dark\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity) );
  }

  .dark\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity) );
  }

  .dark\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity) );
  }

  .dark\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity) );
  }

  .dark\:ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }

  .dark\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity) );
  }

  .dark\:hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity) );
  }

  .peer:checked ~ .dark\:peer-checked\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgb(3 118 227 / var(--tw-text-opacity) );
  }
}

@media (min-width: 640px) {
  .sm\:flex {
    display: flex;
  }

  .sm\:flex-col-6 {
    flex: 0 0 50%;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse) ) );
    margin-bottom: calc(0px * var(--tw-space-y-reverse) );
  }

  .sm\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse) );
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse) ) );
  }
}

@media (min-width: 768px) {
  .md\:block {
    display: block;
  }

  .md\:inline-block {
    display: inline-block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:flex-col-4 {
    flex: 0 0 33.3333%;
  }

  .md\:flex-col-8 {
    flex: 0 0 66.6667%;
  }

  .md\:\!flex-col-2 {
    flex: 0 0 16.6667% !important;
  }

  .md\:\!flex-col-6 {
    flex: 0 0 50% !important;
  }

  .md\:\!flex-col-4 {
    flex: 0 0 33.3333% !important;
  }

  .md\:\!flex-col-8 {
    flex: 0 0 66.6667% !important;
  }

  .md\:flex-col-9 {
    flex: 0 0 75%;
  }

  .md\:flex-col-3 {
    flex: 0 0 25%;
  }

  .md\:flex-col-12 {
    flex: 0 0 100%;
  }

  .md\:flex-col-6 {
    flex: 0 0 50%;
  }

  .md\:flex-col-2 {
    flex: 0 0 16.6667%;
  }

  .md\:\!flex-col-5 {
    flex: 0 0 41.6667% !important;
  }

  .md\:\!flex-col-3 {
    flex: 0 0 25% !important;
  }

  .md\:\!flex-col-12 {
    flex: 0 0 100% !important;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1024px) {
  .lg\:\!block {
    display: block !important;
  }

  .lg\:\!hidden {
    display: none !important;
  }

  .lg\:\!flex-col-3 {
    flex: 0 0 25% !important;
  }

  .lg\:flex-col-3 {
    flex: 0 0 25%;
  }

  .lg\:\!flex-col-6 {
    flex: 0 0 50% !important;
  }

  .lg\:\!flex-col-4 {
    flex: 0 0 33.3333% !important;
  }
}

@media (min-width: 1280px) {
  .xl\:max-w-screen-xl {
    max-width: 1280px;
  }
}

.styled-tooltip.top {
  top: -4px;
  left: 50%;
  transform: translate(-50%, -100%);
}

.styled-tooltip.top:after {
  content: "";
  height: 0;
  width: 0;
  border-top: 6px solid #333;
  border-left: 8px solid rgba(0, 0, 0, 0);
  border-right: 8px solid rgba(0, 0, 0, 0);
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translate(-50%);
}

.styled-tooltip.bottom {
  bottom: -4px;
  left: 50%;
  transform: translate(-50%, 100%);
}

.styled-tooltip.bottom:after {
  content: "";
  height: 0;
  width: 0;
  border-bottom: 6px solid #333;
  border-left: 8px solid rgba(0, 0, 0, 0);
  border-right: 8px solid rgba(0, 0, 0, 0);
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translate(-50%);
}

.styled-tooltip.left {
  top: 50%;
  left: -8px;
  transform: translate(-100%, -50%);
}

.styled-tooltip.left:after {
  content: "";
  height: 0;
  width: 0;
  border-top: 8px solid rgba(0, 0, 0, 0);
  border-bottom: 8px solid rgba(0, 0, 0, 0);
  border-left: 6px solid #333;
  position: absolute;
  top: 50%;
  right: -6px;
  transform: translate(0, -50%);
}

.styled-tooltip.right {
  top: 50%;
  right: -8px;
  transform: translate(100%, -50%);
}

.styled-tooltip.right:after {
  content: "";
  height: 0;
  width: 0;
  border-top: 8px solid rgba(0, 0, 0, 0);
  border-bottom: 8px solid rgba(0, 0, 0, 0);
  border-right: 6px solid #333;
  position: absolute;
  top: 50%;
  left: -6px;
  transform: translate(0, -50%);
}

.styled-tooltip-question-mark:hover .styled-tooltip {
  display: block;
}

.c_ribbon {
  color: #fff;
  width: max(10em, min(50%, 16em));
  text-align: center;
  text-transform: uppercase;
  background-color: #1a539f;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 2px 2px 12px 12px;
  margin: auto;
  padding: 5px;
  font-size: 12px;
  position: relative;
  top: -8px;
  left: 6px;
  box-shadow: -3px 4px 3px -2px rgba(0, 0, 0, .46);
}

.c_ribbon span {
  position: absolute;
}

.c_ribbon .flair-top-left {
  filter: brightness(.5);
  z-index: -1;
  border: 3px solid #3a3f72;
  border-color: rgba(0, 0, 0, 0) #3a3f72 #3a3f72 rgba(0, 0, 0, 0);
  top: -2px;
  left: -7px;
}

.c_ribbon .flair-top-right {
  z-index: -1;
  border: 3px solid rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #3a3f72 #3a3f72;
  top: -2px;
  right: -7px;
}

.c_ribbon .flair-bottom-right {
  z-index: -1;
  border: 3px solid rgba(0, 0, 0, 0);
  border-color: #3a3f72 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #3a3f72;
  bottom: -7px;
  right: -2px;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, .7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient( to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55 );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index) px);
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: pointer;
  direction: ltr;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px rgba(0, 0, 0, .1), 0 2px 15px rgba(0, 0, 0, .05);
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  width: 20px;
  flex-shrink: 0;
  display: flex;
}

.Toastify__toast-icon:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  margin-right: 10px;
}

.Toastify__toast-icon:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: 10px;
}

.Toastify--animate {
  animation-duration: .7s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light, .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear forwards;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  animation: Toastify__spin .65s linear infinite;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=index.46f07a85.css.map */
